.container {
	padding: 2rem;
}

.dash {
	height: 2px;
	width: 14px;
	background-color: #278ea5;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
	width: 103px;
}

.section {
	margin: 20px 0;
}

.radioColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.optionsContainer {
    padding: 10px 16px;	
}

.options {
    max-height: 280px;
    overflow-y: auto;
}
