.inputContainer {
	background: #EFF2F6;
	border-radius: 16px;
	height: 64px;
	border: 1px solid #EFF2F6;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278EA5;
	transition: all 0.3s;
	position: relative;
}

.highlightedInputContainer {
	background: #D5DCE9;
	border-radius: 16px;
	height: 64px;
	border: 1px solid #8291AE;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278EA5;
	transition: all 0.3s;
	position: relative;
}

.disabledInputContainer {
	background: rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	height: 64px;
	border: 1px solid transparent;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278EA5;
	transition: all 0.3s;
	position: relative;
}

.inputContainer:hover {
	border: 1px solid #278EA5;
}

.inputContainer:focus {
	border: 1px solid #278EA5;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(39 142 165 / 20%);
	background: white;
}

.title {
	color: #6E7C91;
	font-size: 12px;
	display: flex;
	align-items: center;
}

.optional {
	margin-left: 12px;
    color: #00000073;
}

.input {
	background: transparent;
	outline: none;
	border: none;
	color: rgb(75, 87, 102);
	font-size: 15px;
	margin-top: auto;
	resize: none;
}

.smallInput {
	background: transparent;
	outline: none;
	border: none;
	color: rgb(75, 87, 102);
	font-size: 14px;
	margin-top: auto;
	resize: none;
}

.smallInputContainer {
	background: #EFF2F6;
	border-radius: 12px;
	height: 56px;
	border: 1px solid #EFF2F6;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278EA5;
	transition: all 0.3s;
	position: relative;
}

.highlightedSmallInputContainer {
	background: #D5DCE9;
	border-radius: 12px;
	height: 56px;
	border: 1px solid #8291AE;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	transition: all 0.3s;
	position: relative;
}

.tooltip {
	margin-left: 12px;
	display: flex;
	align-items: center;
}

.watchButton {
	position: absolute !important;
	right: 12px;
	top: 50%;
    transform: translateY(-50%);
}